import { ActionTree } from 'vuex/types/index';
import { Context } from '@nuxt/types';

import { RootState } from '~/store';

import LandingPage from '~/models/LandingPage';

import {
    defaultTableOptions,
    LandingPagesModuleState,
    state
} from '~/modules/landingPages/store/state';
import {
    LandingPageDeployment,
    LandingPageRevision
} from '~/modules/landingPages/types';
import { mutations, MUTATIONS } from '~/modules/landingPages/store/mutations';
import { getters } from '~/modules/landingPages/store/getters';
import API from '~/modules/landingPages/api';

import { StorageKind } from '~/modules/storage/types';

const STORAGE_KEY_TABLE_OPTIONS = 'lp_table_options';

export default ({ store, app, $storage }: Context) => {
    const actions: ActionTree<LandingPagesModuleState, RootState> = {
        select: ({ commit }, id) => {
            commit(MUTATIONS.SELECT, id);
            commit(MUTATIONS.RESET_FORM_VALIDATIONS);
        },
        unselect: ({ commit }) => {
            commit(MUTATIONS.UNSELECT);
        },
        getSelected: async ({ dispatch, state }) => {
            if (state.selected) {
                await dispatch('get', state.selected);
            }
        },
        localAdd: ({ commit }, payload) => {
            const landingPage = new LandingPage(payload);
            commit(MUTATIONS.ADD_LP, landingPage);
            commit(MUTATIONS.ADD_ID, landingPage.id);
            return landingPage;
        },
        localUpdate: ({ commit }, payload) => {
            const landingPage = new LandingPage(payload);
            commit(MUTATIONS.ADD_LP, landingPage);
            commit(MUTATIONS.ADD_UNSAVED, landingPage.id);
            return landingPage;
        },
        updateLocalCode: ({ dispatch, getters }, code) => {
            dispatch('localUpdate', { ...getters.selected, code });
        },
        requireConfirmation: ({ commit }) => {
            commit(MUTATIONS.SET_CONFIRMATION_REQUIRED, true);
        },
        confirm: ({ commit }) => {
            commit(MUTATIONS.SET_CONFIRMATION_REQUIRED, false);
        },
        localFormValidation: ({ commit }, validation) => {
            commit(MUTATIONS.ADD_FORM_VALIDATION, validation);
        },
        resetLocalFormValidation: ({ commit }) => {
            commit(MUTATIONS.RESET_FORM_VALIDATIONS);
        },
        list: async ({ commit, dispatch, state }, params) => {
            commit(MUTATIONS.START_LOADING);
            commit(MUTATIONS.RESET_ERRORS);

            if (params.itemsPerPage && params.itemsPerPage === -1) {
                params.itemsPerPage = state.totalItems;
            }

            try {
                const { data } = await API.fetchAll(params);

                commit(MUTATIONS.SET_TOTAL_ITEMS, data.meta.total);
                commit(MUTATIONS.RESET_LIST);
                data.data.forEach((landingPage: object) =>
                    dispatch('localAdd', landingPage)
                );
                return data.data;
            } catch (error) {
                dispatch('apiError', error);
            } finally {
                commit(MUTATIONS.STOP_LOADING);
            }
        },
        get: async ({ commit, dispatch }, id) => {
            commit(MUTATIONS.START_LOADING);
            commit(MUTATIONS.RESET_ERRORS);

            try {
                const { data } = await API.fetch(id);
                const landingPage = await dispatch('localAdd', data.data);
                return landingPage;
            } catch (error) {
                dispatch('apiError', error);
            } finally {
                commit(MUTATIONS.STOP_LOADING);
            }
        },
        create: async ({ commit, dispatch }, payload) => {
            commit(MUTATIONS.START_LOADING);
            commit(MUTATIONS.RESET_ERRORS);

            try {
                const { data } = await API.create({
                    ...payload,
                    id: null,
                    type: payload.type.id
                });
                const landingPage = await dispatch('localAdd', data.data);
                // Adding unsaved forces a new landing page to be stored again before deployment
                commit(MUTATIONS.ADD_UNSAVED, landingPage.id);
                return landingPage;
            } catch (error) {
                dispatch('apiError', error);
            } finally {
                commit(MUTATIONS.STOP_LOADING);
            }
        },
        copy: async ({ commit, dispatch }, { id, title }) => {
            commit(MUTATIONS.START_LOADING);
            commit(MUTATIONS.RESET_ERRORS);

            try {
                const { data } = await API.copy({ id, title });
                const copiedLandingPage = await dispatch('localAdd', data.data);
                commit(MUTATIONS.REMOVE_UNSAVED, copiedLandingPage.id);
                return copiedLandingPage;
            } catch (error) {
                dispatch('apiError', error);
            } finally {
                commit(MUTATIONS.STOP_LOADING);
            }
        },
        update: async ({ commit, dispatch }, landingPage) => {
            commit(MUTATIONS.START_LOADING);
            commit(MUTATIONS.RESET_ERRORS);

            try {
                const { data } = await API.update(landingPage.id, {
                    ...landingPage,
                    type: landingPage.type.id
                });
                const updatedLandingPage = await dispatch(
                    'localAdd',
                    data.data
                );

                dispatch('fetchRevisions', updatedLandingPage.id);
                commit(MUTATIONS.REMOVE_UNSAVED, updatedLandingPage.id);
            } catch (error) {
                dispatch('apiError', error);
            } finally {
                commit(MUTATIONS.STOP_LOADING);
            }
        },
        deploy: ({ commit }, { id }: { id: number }) => {
            commit(MUTATIONS.START_LOADING);
            return API.deploy(id).finally(() => {
                commit(MUTATIONS.STOP_LOADING);
            });
        },
        deactivate: async ({ commit, dispatch }, { id, redirectUrl }) => {
            commit(MUTATIONS.START_LOADING);
            commit(MUTATIONS.RESET_ERRORS);

            try {
                const { data } = await API.deactivate(id, redirectUrl);
                const deactivatedLandingPage = await dispatch(
                    'localAdd',
                    data.data
                );
                commit(MUTATIONS.REMOVE_UNSAVED, deactivatedLandingPage.id);
                return deactivatedLandingPage;
            } catch (error) {
                dispatch('apiError', error);
            } finally {
                commit(MUTATIONS.STOP_LOADING);
            }
        },
        reactivate: async ({ commit, dispatch }, id) => {
            commit(MUTATIONS.START_LOADING);
            commit(MUTATIONS.RESET_ERRORS);

            try {
                const { data } = await API.reactivate(id);
                const reactivatedLandingPage = await dispatch(
                    'localAdd',
                    data.data
                );
                commit(MUTATIONS.REMOVE_UNSAVED, reactivatedLandingPage.id);
                return reactivatedLandingPage;
            } catch (error) {
                dispatch('apiError', error);
            } finally {
                commit(MUTATIONS.STOP_LOADING);
            }
        },
        delete: async ({ commit, dispatch }, id) => {
            commit(MUTATIONS.START_LOADING);
            commit(MUTATIONS.RESET_ERRORS);

            try {
                await API.delete(id);
                commit(MUTATIONS.REMOVE_LP, id);
                return true;
            } catch (error) {
                dispatch('apiError', error);
                throw error;
            } finally {
                commit(MUTATIONS.STOP_LOADING);
            }
        },
        uploadImage: (
            { commit },
            { lpId, formData }: { lpId: number; formData: FormData }
        ) => {
            commit(MUTATIONS.START_LOADING);
            return API.uploadImage(lpId, formData).finally(() =>
                commit(MUTATIONS.STOP_LOADING)
            );
        },
        fetchDeployments: async ({ commit, dispatch }, id) => {
            commit(MUTATIONS.START_LOADING);
            try {
                const { data } = await API.fetchDeployments(id);
                commit('deployments/RESET_LIST', null, { root: true });
                data.forEach((item: LandingPageDeployment) => {
                    commit('deployments/ADD', item, { root: true });
                });
            } catch (error) {
                dispatch('apiError', error);
            } finally {
                commit(MUTATIONS.STOP_LOADING);
            }
        },
        fetchRevisions: async ({ commit, dispatch }, id) => {
            commit(MUTATIONS.START_LOADING);
            try {
                const { data } = await API.fetchRevisions(id);
                commit('revisions/RESET_LIST', null, { root: true });
                data.forEach((item: LandingPageRevision) => {
                    commit('revisions/ADD', item, { root: true });
                });
            } catch (error) {
                dispatch('apiError', error);
            } finally {
                commit(MUTATIONS.STOP_LOADING);
            }
        },

        activateRevision: async (
            { commit, dispatch },
            {
                revisionId,
                landingPageId
            }: { revisionId: number; landingPageId: number }
        ) => {
            commit(MUTATIONS.START_LOADING);
            try {
                await API.activateRevision(landingPageId, revisionId);
                await dispatch('get', landingPageId);
                await dispatch('fetchRevisions', landingPageId);
            } catch (error) {
                dispatch('apiError', error);
            } finally {
                commit(MUTATIONS.STOP_LOADING);
            }
        },
        apiError: ({ commit }, error) => {
            if (error && error.response && error.response.data) {
                if (error.response.data.message) {
                    app.$toast.error(error.response.data.message);
                }
                if (error.response.data.errors) {
                    commit(MUTATIONS.SET_ERRORS, error.response.data.errors);
                }
            }
        },
        removeError: ({ commit }, key) => {
            commit(MUTATIONS.REMOVE_ERROR, key);
        },
        setTableOptions: ({ commit, dispatch }, tableOptions) => {
            commit(MUTATIONS.SET_TABLE_OPTIONS, tableOptions);
            dispatch('persistTableOptionsToStorage', tableOptions);
        },
        loadTableOptions: async ({ commit, dispatch }) => {
            const tableOptions = await dispatch('loadTableOptionsFromStorage');
            if (tableOptions) {
                commit(MUTATIONS.SET_TABLE_OPTIONS, tableOptions);
            }
        },
        resetTableOptions: ({ commit, dispatch }) => {
            commit(MUTATIONS.SET_TABLE_OPTIONS, defaultTableOptions);
            dispatch('persistTableOptionsToStorage', defaultTableOptions);
        },
        persistTableOptionsToStorage: (_, tableOptions) => {
            $storage.set({
                kind: StorageKind.session,
                key: STORAGE_KEY_TABLE_OPTIONS,
                value: tableOptions
            });
        },
        loadTableOptionsFromStorage: (_) => {
            return $storage.get({
                kind: StorageKind.session,
                key: STORAGE_KEY_TABLE_OPTIONS
            });
        },
    };

    store.registerModule('landingPages', {
        namespaced: true,
        state,
        getters,
        actions,
        mutations
    });
};
