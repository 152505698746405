import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _774195da = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _45ff7bca = () => interopDefault(import('../pages/auth/forgotten.vue' /* webpackChunkName: "pages/auth/forgotten" */))
const _3e84af2c = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _804f1960 = () => interopDefault(import('../pages/auth/reset.vue' /* webpackChunkName: "pages/auth/reset" */))
const _cf5cccba = () => interopDefault(import('../pages/auth/welcome.vue' /* webpackChunkName: "pages/auth/welcome" */))
const _983c8fea = () => interopDefault(import('../modules/users/pages/index.vue' /* webpackChunkName: "" */))
const _1fecd7da = () => interopDefault(import('../modules/users/pages/create.vue' /* webpackChunkName: "" */))
const _7f8dbde1 = () => interopDefault(import('../modules/users/pages/edit.vue' /* webpackChunkName: "" */))
const _9d0db0a2 = () => interopDefault(import('../modules/stores/pages/index.vue' /* webpackChunkName: "" */))
const _7737c9bd = () => interopDefault(import('../modules/stores/pages/edit.vue' /* webpackChunkName: "" */))
const _66b4942a = () => interopDefault(import('../modules/landingPages/pages/index.vue' /* webpackChunkName: "" */))
const _d0db8858 = () => interopDefault(import('../modules/landingPages/pages/create.vue' /* webpackChunkName: "" */))
const _44cfb348 = () => interopDefault(import('../modules/landingPages/pages/create/base.vue' /* webpackChunkName: "" */))
const _0c2d5dd4 = () => interopDefault(import('../modules/landingPages/pages/create/final-order-confirmation.vue' /* webpackChunkName: "" */))
const _8f7251ec = () => interopDefault(import('../modules/landingPages/pages/create/cross-sell.vue' /* webpackChunkName: "" */))
const _2e369ee6 = () => interopDefault(import('../modules/landingPages/pages/create/sepa-enhancement.vue' /* webpackChunkName: "" */))
const _12bd7938 = () => interopDefault(import('../modules/landingPages/pages/create/upsell.vue' /* webpackChunkName: "" */))
const _d876fb00 = () => interopDefault(import('../modules/landingPages/pages/create/video.vue' /* webpackChunkName: "" */))
const _0ed8f8f4 = () => interopDefault(import('../modules/landingPages/pages/create/email-signup.vue' /* webpackChunkName: "" */))
const _16347047 = () => interopDefault(import('../modules/landingPages/pages/create/webinar-registration.vue' /* webpackChunkName: "" */))
const _389c7cde = () => interopDefault(import('../modules/landingPages/pages/create/free-signup.vue' /* webpackChunkName: "" */))
const _95e262e6 = () => interopDefault(import('../modules/landingPages/pages/create/multi-offer.vue' /* webpackChunkName: "" */))
const _7338b2bf = () => interopDefault(import('../modules/credentials/pages/index.vue' /* webpackChunkName: "" */))
const _238fa466 = () => interopDefault(import('../modules/credentials/pages/new.vue' /* webpackChunkName: "" */))
const _2e5ac6a6 = () => interopDefault(import('../modules/credentials/pages/edit.vue' /* webpackChunkName: "" */))
const _771d9454 = () => interopDefault(import('../modules/fileManager/pages/index.vue' /* webpackChunkName: "" */))
const _147cf44c = () => interopDefault(import('../modules/tenants/pages/index.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/en",
    component: _774195da,
    name: "index___en"
  }, {
    path: "/auth/forgotten",
    component: _45ff7bca,
    name: "auth-forgotten___de"
  }, {
    path: "/auth/login",
    component: _3e84af2c,
    name: "auth-login___de"
  }, {
    path: "/auth/reset",
    component: _804f1960,
    name: "auth-reset___de"
  }, {
    path: "/auth/welcome",
    component: _cf5cccba,
    name: "auth-welcome___de"
  }, {
    path: "/en/auth/forgotten",
    component: _45ff7bca,
    name: "auth-forgotten___en"
  }, {
    path: "/en/auth/login",
    component: _3e84af2c,
    name: "auth-login___en"
  }, {
    path: "/en/auth/reset",
    component: _804f1960,
    name: "auth-reset___en"
  }, {
    path: "/en/auth/welcome",
    component: _cf5cccba,
    name: "auth-welcome___en"
  }, {
    path: "/",
    component: _774195da,
    name: "index___de"
  }, {
    path: "/users",
    component: _983c8fea,
    name: "users___de"
  }, {
    path: "/en/users",
    component: _983c8fea,
    name: "users___en"
  }, {
    path: "/users/create",
    component: _1fecd7da,
    name: "users-create___de"
  }, {
    path: "/en/users/create",
    component: _1fecd7da,
    name: "users-create___en"
  }, {
    path: "/users/edit",
    component: _7f8dbde1,
    name: "users-edit___de"
  }, {
    path: "/en/users/edit",
    component: _7f8dbde1,
    name: "users-edit___en"
  }, {
    path: "/stores",
    component: _9d0db0a2,
    name: "stores___de"
  }, {
    path: "/en/stores",
    component: _9d0db0a2,
    name: "stores___en"
  }, {
    path: "/stores/edit",
    component: _7737c9bd,
    name: "stores-edit___de"
  }, {
    path: "/en/stores/edit",
    component: _7737c9bd,
    name: "stores-edit___en"
  }, {
    path: "/landingpages",
    component: _66b4942a,
    name: "landingpages___de"
  }, {
    path: "/en/landingpages",
    component: _66b4942a,
    name: "landingpages___en"
  }, {
    path: "/landingpages/create",
    component: _d0db8858,
    name: "landingpages-create___de"
  }, {
    path: "/en/landingpages/create",
    component: _d0db8858,
    name: "landingpages-create___en"
  }, {
    path: "/landingpages/create/base",
    component: _44cfb348,
    name: "landingpages-create-base___de"
  }, {
    path: "/en/landingpages/create/base",
    component: _44cfb348,
    name: "landingpages-create-base___en"
  }, {
    path: "/landingpages/create/final-order-confirmation",
    component: _0c2d5dd4,
    name: "landingpages-create-final-order-confirmation___de"
  }, {
    path: "/en/landingpages/create/final-order-confirmation",
    component: _0c2d5dd4,
    name: "landingpages-create-final-order-confirmation___en"
  }, {
    path: "/landingpages/create/cross-sell",
    component: _8f7251ec,
    name: "landingpages-create-cross-sell___de"
  }, {
    path: "/en/landingpages/create/cross-sell",
    component: _8f7251ec,
    name: "landingpages-create-cross-sell___en"
  }, {
    path: "/landingpages/create/sepa-enhancement",
    component: _2e369ee6,
    name: "landingpages-create-sepa-enhancement___de"
  }, {
    path: "/en/landingpages/create/sepa-enhancement",
    component: _2e369ee6,
    name: "landingpages-create-sepa-enhancement___en"
  }, {
    path: "/landingpages/create/upsell",
    component: _12bd7938,
    name: "landingpages-create-upsell___de"
  }, {
    path: "/en/landingpages/create/upsell",
    component: _12bd7938,
    name: "landingpages-create-upsell___en"
  }, {
    path: "/landingpages/create/video",
    component: _d876fb00,
    name: "landingpages-create-video___de"
  }, {
    path: "/en/landingpages/create/video",
    component: _d876fb00,
    name: "landingpages-create-video___en"
  }, {
    path: "/landingpages/create/email-signup",
    component: _0ed8f8f4,
    name: "landingpages-create-email-signup___de"
  }, {
    path: "/en/landingpages/create/email-signup",
    component: _0ed8f8f4,
    name: "landingpages-create-email-signup___en"
  }, {
    path: "/landingpages/create/webinar-registration",
    component: _16347047,
    name: "landingpages-create-webinar-registration___de"
  }, {
    path: "/en/landingpages/create/webinar-registration",
    component: _16347047,
    name: "landingpages-create-webinar-registration___en"
  }, {
    path: "/landingpages/create/free-signup",
    component: _389c7cde,
    name: "landingpages-create-free-signup___de"
  }, {
    path: "/en/landingpages/create/free-signup",
    component: _389c7cde,
    name: "landingpages-create-free-signup___en"
  }, {
    path: "/landingpages/create/multi-offer",
    component: _95e262e6,
    name: "landingpages-create-multi-offer___de"
  }, {
    path: "/en/landingpages/create/multi-offer",
    component: _95e262e6,
    name: "landingpages-create-multi-offer___en"
  }, {
    path: "/credentials",
    component: _7338b2bf,
    name: "credentials___de"
  }, {
    path: "/en/credentials",
    component: _7338b2bf,
    name: "credentials___en"
  }, {
    path: "/credentials/new",
    component: _238fa466,
    name: "credentials-new___de"
  }, {
    path: "/en/credentials/new",
    component: _238fa466,
    name: "credentials-new___en"
  }, {
    path: "/credentials/edit",
    component: _2e5ac6a6,
    name: "credentials-edit___de"
  }, {
    path: "/en/credentials/edit",
    component: _2e5ac6a6,
    name: "credentials-edit___en"
  }, {
    path: "/filemanager",
    component: _771d9454,
    name: "filemanager___de"
  }, {
    path: "/en/filemanager",
    component: _771d9454,
    name: "filemanager___en"
  }, {
    path: "/tenants",
    component: _147cf44c,
    name: "tenants___de"
  }, {
    path: "/en/tenants",
    component: _147cf44c,
    name: "tenants___en"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
