import { LandingPageTableOptions } from '../types';
import LandingPage from '~/models/LandingPage';

export const defaultTableOptions = {
    groupBy: [],
    groupDesc: [],
    itemsPerPage: 10,
    multiSort: true,
    mustSort: false,
    page: 1,
    sortBy: ['updated_at'],
    sortDesc: [true],
    id: '',
    product_shorthand: '',
    title: '',
    url: '',
    type: null,
    store_id: null,
    status: null,
    username: '',
    updated_at: null,
} as LandingPageTableOptions;

export const state = () => ({
    ids: [] as Array<string | number>,
    list: {} as { [id: string]: LandingPage },
    errors: {},
    unsaved: [] as Array<string | number>,
    totalItems: 0,
    selected: null, // Id of the currently selected landing page
    isLoading: false,
    requiresConfirmation: false,
    formValidations: {} as { [formName: string]: boolean },
    tableOptions: defaultTableOptions
});

export type LandingPagesModuleState = ReturnType<typeof state>;

export default state;
