/**
 * Represents a full a set of configurations that are applied
 * to the deployed form wrapper. The configuration is
 * usually a member of a landing page object.
 */
export default class FormWrapperConfig {
    constructor (args) {
        if (!args) {
            return new FormWrapperConfig({});
        }
        this.insert_uniform = true;
        this.template_id = args.template_id || 1;
        this.settings = args.settings || {
            form_backgroundcolor: null,
            fields: {
                submit: {
                    backgroundcolor: null,
                    width: null,
                    height: null,
                },
                company: {
                    label: null
                }
            }
        };
    }
}
